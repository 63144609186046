import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Button from "../components/ui/button";
import Title from "../components/ui/title";
import Section from "../components/layout/section";
import TwoContainersLayout from "../components/layout/twoContainersLayout";

import * as styles from "./partners.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { SEOv2 } from "../components/seov2";

const Partners = ({ data }) => (
  <Layout>
    <Section type="nearly-black" className={styles.firstSectionContainer}>
      <TwoContainersLayout>
        <div className={styles.left}>
          <span className={styles.heading}>Партньори</span>
        </div>
        <div className={styles.right}>
          <StaticImage
            className={styles.heroImage}
            objectFit="contain"
            alt="partners hero image"
            src="../assets/images/partners.png"
            placeholder="blurred"
          />
        </div>
      </TwoContainersLayout>
    </Section>
    <Section type="white" className={styles.logoSection}>
      {data.allStrapiPartner.edges.map((partner) => {
        return (
          <a href={partner.node.Url} target="_blank" key={partner.node.id}>
            <img alt="" src={`${partner.node.Logo.url}`} />
          </a>
        );
      })}
    </Section>
    <Section type="nearly-black" className={styles.sectionContainer}>
      <Title className={styles.title}>Свържете се с нас</Title>
      <Button
        className={styles.button}
        inverted
        title={"Свържете се с нас"}
        to="/contacts"
      />
    </Section>
  </Layout>
);

export const query = graphql`
  query {
    allStrapiPartner {
      edges {
        node {
          Url
          id
          Logo {
            url
          }
        }
      }
    }
  }
`;

export default Partners;

export const Head = () => <SEOv2 title="Партньори" previewImg="partners" />;
