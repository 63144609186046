import * as React from "react"
import * as SectionStyles from "./section.module.scss";
import classNames from "classnames";

const Section = ({ type, className, children }) => {
    const containerStyles = classNames(
        SectionStyles.wrapper,
        {
            [SectionStyles.nearBlack]: type === 'nearly-black',
            [SectionStyles.white]: type === 'white',
            [SectionStyles.lightestGreen]: type === 'lightest-green',
            [SectionStyles.green]: type === 'green',
        }
    );
    const innerStyles = classNames(
        SectionStyles.inner,
        className,
    );

    return (
        <div className={containerStyles}>
            <div className={innerStyles}>
                {children}
            </div>
        </div>
    )
}

export default Section
