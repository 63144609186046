// extracted by mini-css-extract-plugin
export var button = "partners-module--button--8d7bc";
export var firstSectionContainer = "partners-module--firstSectionContainer--c68ef";
export var heading = "partners-module--heading--ea003";
export var heroImage = "partners-module--heroImage--4cbe6";
export var img = "partners-module--img--216cc";
export var left = "partners-module--left--f9692";
export var logoSection = "partners-module--logoSection--8bdda";
export var right = "partners-module--right--9e1f0";
export var sectionContainer = "partners-module--sectionContainer--e28e1";
export var title = "partners-module--title--70c55";